import React, { useState } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { gql, useApolloClient } from '@apollo/client';

import Loading from './loading';
import Chat from './Chat';
import Link, { AnchorLink } from 'components/Link';
import Button from 'components/forms/Button';

//#region
const ChatClosed = styled.p`
	margin: 0;
	flex-grow: 1;
`;
//#endregion

export const GET_CHAT_SERVICE_STATUS = gql`
	query chatServiceStatus($serviceId: Int!) {
		chatServiceStatus(serviceId: $serviceId) {
			Failed
			CorrelationId
			ServiceId
			Name
			ServiceStatus
			CloseTime
			OpenTime
			ResultCode
		}
	}
`;

export default function TrioChatCard({
	serviceId,
	chatId,
	title,
	fallBackContact = 'phone',
}) {
	const [chatOpen, setChatOpen] = useState(true);
	const [loading, setLoading] = useState(true);
	const client = useApolloClient();

	client
		.query({
			query: GET_CHAT_SERVICE_STATUS,
			variables: {
				serviceId: serviceId ? serviceId : 161,
			},
		})
		.catch(error => {
			console.error(error);
			setLoading(false);
			return {};
		})
		.then(({ data }) => {
			let openingHours = {};
			if (data && data.chatServiceStatus) {
				openingHours = data.chatServiceStatus;
				if (openingHours?.ServiceStatus === 'Open') {
					setLoading(false);
					setChatOpen(true);
				} else {
					setLoading(false);
					setChatOpen(false);
				}
			}
		});

	return (
		<Chat
			title={title}
			open={chatOpen}
			statusText={
				chatOpen
					? 'Kundebehandler er tilgjengelig for chat'
					: 'Ingen kundebehandlere er tilgjengelige for chat'
			}>
			{loading ? (
				<Loading />
			) : (
				<>
					{chatOpen ? (
						<Button
							title={`Start Chat med ${title}`}
							onClick={() => {
								navigate(
									chatId
										? `/kundeservice/chat/start-chat?chatRoute=${chatId}`
										: '/kundeservice/chat/start-chat?chatRoute=altibox'
								);
							}}>
							Start chat
						</Button>
					) : (
						<ChatClosed>
							{fallBackContact === 'form' ? (
								<>
									Ingen kundebehandlere tilgjengelig for chat.{' '}
									<br />
									Kontakt oss gjerne via vårt{' '}
									<Link
										to={`/kundeservice/kontaktskjema/${
											chatId === 'strom'
												? 'strom'
												: 'telekom'
										}`}
										title="Kontakt kundeservice via kontaktskjema">
										kontaktskjema
									</Link>
								</>
							) : (
								<>
									Ingen kundebehandlere tilgjengelig for chat.{' '}
									<br />
									Ring oss gjerne på{' '}
									<AnchorLink
										href="tel:004774150200"
										title="Ring kundeservice på telefon +47 741 50 200">
										+47 741 50 200
									</AnchorLink>
								</>
							)}
						</ChatClosed>
					)}
				</>
			)}
		</Chat>
	);
}
