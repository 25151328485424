import React, { useEffect, useState } from 'react';
import Button from 'components/forms/Button';
import InputField from 'components/forms/InputField';
import styled from 'styled-components';
import { PrivacyPolicyCheckbox } from 'components/consent-portal/useSaveConsent';

const Wrapper = styled.div`
	max-width: 500px;
	margin: 0 auto;
	text-align: center;
`;

export default function TrioChat() {
	const [chatRoute, setChatRoute] = useState('altibox');

	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const route = params.get('chatRoute');

		if (route) {
			setChatRoute(route);
		}
	}, []);

	return (
		<Wrapper>
			<form
				action="https://chat.nte.no/CC1/WebRtcChat/Home/Chat"
				method="post"
				style={{ textAlign: 'left' }}>
				<input name="ServiceId" value="162" type="hidden" />
				<input name="Language" value="no_NO" type="hidden" />
				<input name="FormData" value="" type="hidden" />
				<input name="routecat" type="hidden" value={chatRoute} />

				<InputField
					type="text"
					name="d.name"
					placeholder="Navn"
					required
				/>

				<InputField
					type="email"
					name="maddr"
					placeholder="E-post"
					required
				/>

				<PrivacyPolicyCheckbox hidetitle="true" />

				<Button>Start chat</Button>
			</form>
		</Wrapper>
	);
}
