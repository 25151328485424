import React from 'react';
import styled, { css } from 'styled-components';
import Status from './Status';
import Heading from 'libs/heading';
//import WebCallBack from './WebCallBack';

const Wrapper = styled.div`
	background: white;
	border-radius: ${p => p.theme.utils.borderRadius};
	box-shadow: ${p => p.theme.utils.boxShadow};
	width: calc(50% - 20px);
	margin: 0 10px 20px;
	${p =>
		p.theme.media.smallOnly(css`
			width: 100%;
			margin: 0 0 20px;
		`)}
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	text-align: center;
	padding: 40px 30px;
	height: 100%;
	${p =>
		p.theme.media.smallOnly(css`
			padding: 30px 20px;
		`)}
`;

const InnerContent = styled.div`
	flex-grow: 1;
	position: relative;
	button {
		margin-top: 10px;
	}
`;

const Icon = styled.div`
	display: inline-block;
	position: relative;
	margin-bottom: 5px;
	${p =>
		p.theme.media.medium(css`
			height: 30px;
			margin-bottom: 0;
		`)}
	svg {
		display: block;
	}
`;

//#endregion

export default function Chat({
	title = 'Chat',
	open,
	statusText = '',
	children,
}) {
	return (
		<Wrapper>
			<Container status={open}>
				<div>
					<Icon>
						<Status open={open} text={statusText} />
					</Icon>
					<Heading level="h2" className="h3">
						{title}
					</Heading>
				</div>
				<InnerContent>{children}</InnerContent>
				{/* <WebCallBack /> */}
			</Container>
		</Wrapper>
	);
}
